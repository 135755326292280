import { Configuration } from "@azure/msal-browser";

const clientId: string | undefined = process.env.REACT_APP_CLIENT_ID;
if (!clientId) {
  console.error("REACT_APP_CLIENT_ID is not set");
  throw new Error("REACT_APP_CLIENT_ID is not set");
}

const tenantId: string | undefined = process.env.REACT_APP_TENANT_ID;
if (!tenantId) {
  console.error("REACT_APP_TENANT_ID is not set");
  throw new Error("REACT_APP_TENANT_ID is not set");
}

const redirectUri: string | undefined = process.env.REACT_APP_REDIRECT_URI;
if (!redirectUri) {
  console.error("REACT_APP_REDIRECT_URI is not set");
  throw new Error("REACT_APP_REDIRECT_URI is not set");
}

export const msalConfig: Configuration = {
  auth: {
    clientId: clientId,
    authority: `https://login.microsoftonline.com/${tenantId}/v2.0`,
    redirectUri: redirectUri, 
  },
};

export const loginRequest = {
  scopes: ["User.Read"]
};
