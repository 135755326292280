import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ChatPage from "./chat/ChatPage";
import LoginPage from "./login/LoginPage";
import { loginRequest } from "./login/msalConfig";
import ProtectedRoute from "./ProtectedRoute";

function App() {
  const INFRAVISION_AI_SERVER_URL: string =
    process.env.REACT_APP_INFRAVISION_AI_SERVER_URL || "";

  if (INFRAVISION_AI_SERVER_URL === "") {
    throw new Error("REACT_APP_INFRAVISION_AI_SERVER_URL is not set");
  }

  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState<string | null>(null);

  // Function to get token silently after login
  const getAccessToken = async () => {
    if (accounts.length > 0) {
      try {
        await instance.initialize(); // Ensure MSAL is initialized
        const response = await instance.acquireTokenSilent({
          ...loginRequest,
          account: accounts[0],
        });
        setAccessToken(response.accessToken);
      } catch (error) {
        console.error("Token acquisition error:", error);
      }
    }
  };

  // Function to send request to API
  const sendRequest = async () => {
    if (accessToken) {
      try {
        console.log(
          "Sending request to API...",
          INFRAVISION_AI_SERVER_URL + "/api/chat"
        );
        const response = await fetch(INFRAVISION_AI_SERVER_URL + "/api/chat", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ message: "Hello, API!" }),
        });
        const data = await response.json();
        console.log("API response:", data);
      } catch (error) {
        console.error("API request error:", error);
      }
    }
  };

  // Automatically try to get the token after login
  useEffect(() => {
    if (accounts.length > 0) {
      getAccessToken();
    }
  }, [accounts]);

  const isAuthenticated = useIsAuthenticated();

  return (
    <BrowserRouter>
      <Routes>
        {/* Default route, redirect based on authentication status */}
        <Route
          path="/"
          element={
            isAuthenticated ? (
              <Navigate to="/chat" replace />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />

        {/* Login route */}
        <Route path="/login" element={<LoginPage />} />

        {/* Protected chat route */}
        <Route
          path="/chat"
          element={
            <ProtectedRoute>
              <ChatPage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );

  return (
    <div className="App">
      <header className="App-header">
        <AuthenticatedTemplate>
          <div>
            <h2>Access Token:</h2>
            <p
              style={{
                maxWidth: "60vw",
                wordWrap: "break-word",
                fontSize: "small",
              }}
            >
              {accessToken ? accessToken : "No token available"}
            </p>
            <p>Welcome, {accounts[0]?.name}</p>
            <button onClick={sendRequest} className="App-link">
              Send Request to API
            </button>
          </div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <LoginPage />
          {/* <button onClick={handleLogin} className="App-link">
            Login with Microsoft
          </button> */}
        </UnauthenticatedTemplate>
      </header>
    </div>
  );
}

export default App;
