import React from "react";

interface ChatBubbleProps {
  message: string;
  sender: string;
  bgClassName?: string;
  textClassName?: string;
}

const ChatBubble: React.FC<ChatBubbleProps> = ({
  sender,
  message,
  bgClassName = "bg-neutral-100",
  textClassName = "text-neutral-900",
}) => {
  return (
    <div
      className={`flex flex-col w-5/6 my-5 border border-neutral-300 dark:border-none rounded-xl p-5 shadow-md ${bgClassName} ${textClassName}`}
    >
      <p className="font-bold">{sender}</p>
      <div
        className="w-full flex flex-col"
        dangerouslySetInnerHTML={{ __html: message }}
      />
    </div>
  );
};

export default ChatBubble;
