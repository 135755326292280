import React from "react";
import ChatBubble from "./ChatBubble";

interface BotChatBubbleProps {
  message: string;
}

const BotChatBubble: React.FC<BotChatBubbleProps> = ({ message }) => {
  return (
    <div className="flex justify-start">
      <ChatBubble
        sender="Infravision AI"
        message={message}
        bgClassName="bg-neutral-100 dark:bg-neutral-700"
        textClassName="text-neutral-900 dark:text-neutral-100"
      />
    </div>
  );
};

export default BotChatBubble;
