import { useMsal } from "@azure/msal-react";
import { useState } from "react";
import BrandLogo from "../common/BrandLogo";
import BrandPrimaryButton from "../common/BrandPrimaryButton";
import HorizontalDivider from "../common/HorizontalDivider";
import ProductLogo from "../common/ProductLogo";
import { loginRequest } from "./msalConfig";

function LoginPage() {
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState<string | null>(null);

  const handleLogin = async () => {
    try {
      await instance.initialize(); // Ensure MSAL is initialized
      const response = await instance.loginPopup(loginRequest);

      // Save the access token
      setAccessToken(response.accessToken);
      localStorage.setItem("accessToken", response.accessToken);

      // Save the logged in user's name
      const username = response.account?.name || "KUKA User";
      localStorage.setItem("username", username);

      // Redirect to /chat after login
      window.location.href = "/chat";
    } catch (error) {
      console.error("Login error:", error);
    }
  };

  return (
    <div className="">
      <div className="flex flex-col items-center justify-center min-h-screen bg-brand-complex-gradient">
        <div className="w-full max-w-md p-10 space-y-8">
          <div className="flex flex-col">
            <div className="w-3/4 mx-auto">
              <BrandLogo />
            </div>
            <ProductLogo />
          </div>
          <HorizontalDivider />
          <div className="mt-6">
            <BrandPrimaryButton
              type="button"
              onClick={handleLogin}
              className="w-full"
            >
              KUKA SSO Login
            </BrandPrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
