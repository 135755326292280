import React from 'react';

const HorizontalDivider: React.FC = () => {
    return (
        <div className="flex items-center justify-center">
            <div className="w-full h-0.5 bg-gradient-to-r from-transparent via-neutral-300 dark:via-neutral-700 to-transparent"></div>
        </div>
    );
}

export default HorizontalDivider;