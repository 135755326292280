import React from 'react';

const ProductLogo: React.FC = () => {
    return (
        <div className="flex items-center justify-center text-neutral-900 dark:text-neutral-100">
            <p className="text-2xl font-bold">infravision</p>
            <p className="text-2xl">_ai</p>
        </div>
    );
}

export default ProductLogo;