import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { msalConfig } from './login/msalConfig';

const msalInstance = new PublicClientApplication(msalConfig);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <div>
        <App />
      </div>
    </MsalProvider>
  </React.StrictMode>
);
