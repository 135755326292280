import Send from "@mui/icons-material/Send";
import React from "react";
import BrandLogo from "../common/BrandLogo";
import HorizontalDivider from "../common/HorizontalDivider";
import ProductLogo from "../common/ProductLogo";
import VerticalDivider from "../common/VerticalDivider";
import BotChatBubble from "./BotChatBubble";
import ThemeSwitchButton from "./ThemeSwitchButton";
import UserChatBubble from "./UserChatBubble";

const ChatPage: React.FC = () => {
  const INFRAVISION_AI_SERVER_URL: string =
    process.env.REACT_APP_INFRAVISION_AI_SERVER_URL || "";

  if (INFRAVISION_AI_SERVER_URL === "") {
    throw new Error("REACT_APP_INFRAVISION_AI_SERVER_URL is not set");
  }

  const [message, setMessage] = React.useState<string>("");
  const [messages, setMessages] = React.useState<React.ReactNode[]>([]);
  const accessToken = localStorage.getItem("accessToken");

  const clearMessageBox = () => {
    setMessage("");
  };

  const addUserMessage = (message: string) => {
    const newMessage = <UserChatBubble message={message} />;
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  const addBotMessage = (message: string) => {
    const newMessage = <BotChatBubble message={message} />;
    setMessages((prevMessages) => [...prevMessages, newMessage]);
  };

  const sendMessageToServer = async (message: string) => {
    if (!accessToken) {
      return "I cannot see your access token 👀";
    }

    try {
      console.log("Asked:", message);
      const response = await fetch(INFRAVISION_AI_SERVER_URL + "/api/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ message: message }),
      });
      const data = await response.json();
      console.log("API response:", data);
      return data.reply;
    } catch (error) {
      console.error("API request error:", error);
      return "I am sorry, I cannot answer your question at the moment 😭";
    }
  };

  const sendMessage = async () => {
    clearMessageBox();
    addUserMessage(message);
    addBotMessage(await sendMessageToServer(message));
  };

  const onSendClick = () => {
    sendMessage();
  };

  return (
    <div className="">
      <div className="flex h-screen bg-brand-complex-gradient">
        <div className="hidden md:block w-96 flex-col p-10 m-5">
          <div className="mb-8">
            <BrandLogo />
            <ProductLogo />
          </div>
          <div className="my-5">
            <HorizontalDivider />
          </div>
          <div className="flex justify-center">
            <ThemeSwitchButton />
          </div>
          <div className="my-5">
            <HorizontalDivider />
          </div>
          <div className="my-10">
            <div
              className="border p-2 ease-linear duration-100 w-full text-center bg-white dark:bg-transparent shadow-md dark:shadow-none hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer"
              onClick={() => window.location.reload()}
            >
              <p className="text-black dark:text-white">New Chat</p>
            </div>
          </div>
        </div>
        <div className="hidden md:block m-5 w-2">
          <VerticalDivider />
        </div>
        <div className="grow flex flex-col h-screen w-full">
          <div className="pb-52 flex-grow overflow-y-scroll mask-gradient-bottom">
            <div id="chat-container" className="mx-12 md:mx-24 flex flex-col w-auto">
              {messages.map((msg, index) => (
                <React.Fragment key={index}>{msg}</React.Fragment>
              ))}
            </div>
          </div>
          <div className="h-24 flex flex-shrink-0 justify-center">
            <div className="mx-10 top-0 h-1/2 w-full flex shadow-md">
              <input
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                type="text"
                className="flex-grow bg-neutral-100 dark:bg-neutral-800 p-2 border border-neutral-300 dark:border-none focus:outline-none text-neutral-900 dark:text-neutral-100"
                placeholder="Type your message here..."
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onSendClick();
                  }
                }}
              />
              <div className="hover:cursor-pointer border border-neutral-300 dark:border-none bg-KUKA-industrial-orange hover:bg-KUKA-industrial-orange-darker h-full aspect-square flex justify-center items-center">
                <Send onClick={onSendClick} className="text-neutral-100" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatPage;
