import React from 'react';

const VerticalDivider: React.FC = () => {
    return (
        <div className="flex items-center justify-center h-full">
            <div className="h-full w-0.5 bg-gradient-to-b from-transparent via-neutral-300 dark:via-neutral-700 to-transparent"></div>
        </div>
    );
}

export default VerticalDivider;