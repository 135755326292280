import React from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    children: React.ReactNode;
}


const BrandPrimaryButton: React.FC<ButtonProps> = ({ children, className = '', ...props }) => {
    return (
        <button
            className={`bg-KUKA-industrial-orange hover:bg-KUKA-industrial-orange-darker border-none text-white font-bold py-2 px-4 ${className}`}
            {...props}
        >
            {children}
        </button>
    );
};

export default BrandPrimaryButton;
