import React from "react";
import ChatBubble from "./ChatBubble";

interface UserChatBubbleProps {
  message: string;
}

const UserChatBubble: React.FC<UserChatBubbleProps> = ({ message }) => {
  const getUsernameFromLocalStorage = (): string => {
    return localStorage.getItem("username") || "KUKA User";
  };

  return (
    <div className="flex justify-end">
      <ChatBubble
        sender={getUsernameFromLocalStorage()}
        message={message}
        bgClassName="bg-KUKA-industrial-orange border"
        textClassName="text-neutral-100"
      />
    </div>
  );
};

export default UserChatBubble;
