import Contrast from '@mui/icons-material/Contrast';
import React from 'react';

const ThemeSwitchButton: React.FC = () => {
    // Get initial theme from localStorage or default to light mode
    const [isDarkMode, setIsDarkMode] = React.useState(() => {
        return localStorage.getItem('theme') === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches);
    });

    // Toggle dark mode
    const toggleTheme = () => {
        const newTheme = !isDarkMode;
        setIsDarkMode(newTheme);
        localStorage.setItem('theme', newTheme ? 'dark' : 'light');

        // Apply or remove the `dark` class on the html element
        if (newTheme) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    };

    // Sync theme with stored preference on load
    React.useEffect(() => {
        if (isDarkMode) {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    }, [isDarkMode]);

    return (
        <div 
            className='hover:cursor-pointer text-neutral-900 dark:text-neutral-100 hover:text-KUKA-industrial-orange-darker ease-in-out duration-300'
            onClick={toggleTheme}
            >
            <Contrast />
        </div>
    );
}

export default ThemeSwitchButton;