import React from 'react';

const BrandLogo: React.FC = () => {
    return (
        <div className="flex items-center justify-center">
            <img src="/images/KUKA/KUKA-Logo-Orange.svg" alt="logo" />
        </div>
    );
}

export default BrandLogo;