import { useMsal } from "@azure/msal-react";
import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { accounts } = useMsal();

  // Check if the user is authenticated by looking for an active account
  const isAuthenticated = accounts.length > 0;

  if (!isAuthenticated) {
    // Redirect to login if the user is not authenticated
    return <Navigate to="/login" replace />;
  }

  // Render the protected content if the user is authenticated
  return <>{children}</>;
};

export default ProtectedRoute;
